.input-field {
    .MuiInputBase-input{
      min-height: 24px !important;
    }
    .MuiInputBase-root {
      background-color: #fcf8f8;
    }
}
.change-btn {
    width: "186px";
    height: "56px";
        padding: "20px 32px";
        gap: "10px";
        border-radius: "16px";
        border: "1px solid #E47004";
        opacity: "1";
        color: "#E47004";
        text-transform: "capitalize";
        font-weight: 700;
        font-size: "14px";
}
.selectedFileDetailsContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding: 32px 0;
    min-height: 260px;
    width: 100%;

  }
  
  /* Media query for screens narrower than 700px */
  @media (max-width: 700px) {
    .selectedFileDetailsContainer {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
}
.acceptedFileMessage {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: #1C1B1B;
    line-height: 33px;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Media query for screens narrower than 700px */
  @media (max-width: 700px) {
    .acceptedFileMessage {
      text-align: center;
    }
  }
  