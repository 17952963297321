.category {
height: 100vh !important;
width: 100vw !important;
}

.leftButton {
  padding: 12px;
  text-align: center;
}
.leftButton:hover { 
  background-color: transparent;
}
.wrapper {
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-end;
  bottom: 3px;
  border-radius: 9px;
}

.wrapper-background {
  background: rgba(0, 0, 0, 0.7);
}

.content {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 12px 16px;
  color: #fff;
  overflow: hidden;
  padding-right: 0;
  position: absolute;
  bottom: 0;
}

.mainWrap {
  position: relative;
  padding: 3px;
  height: 150;
  width: 150;
  box-shadow: none !important;
}

.sub-content {
  font-weight: bold;
}

.img {
  border-radius: 9px;
}

.css-pdwytc-MuiPaper-root {
  border: 4px solid #fff;
  outline: 2px solid black;

  /* border-image-source: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%); */
}

.onbaording-selected-image {
  border-radius: 6px;
  margin: 0px;
  padding: 2px;
  background: linear-gradient(180deg, #ef2a4c 0%, #6c318a 100%);

}

.category .MuiAppBar-colorPrimary {
  color: black;
  background-color: #fff;
}

.category .MuiPaper-elevation4 {
  box-shadow: none !important;
}

.category .wrapper .check {
  position: absolute;
  z-index: 1111;
  top: 34%;
  left: 40%
}

.category .wrapper .check-hide {
  display: none;
}
.category .MuiToolbar-gutters {
  padding-left: 1px !important;
  padding-right: 20px !important;
}
.category .MuiTypography-h6 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  letter-spacing: -1px;
  color: #11142D !important;
  text-align: center !important;
  padding: 0px 35px !important;
}
.category .wrapper .check-circle-icon {
  font-size: 40px !important;
  color: #fff !important;
}
.category .arrow-icon{
  color: #1E1F20;
  margin-top: -25px;
  margin-left: -60px;
}
.category .skip-btn{
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px;
  color: #F42074 !important;
  text-transform: capitalize !important;
  margin: auto;
  display: flex;
}
.category .proceed-content{ justify-content: center !important;width: 300px !important;}

.category .proceed-content .proceed-btn-disabled {
  border-radius: 20px !important;
  background: #D7DBE5 !important;
  font-style: normal !important;
  font-weight: 700 !important;
  height: 50px !important;
  font-size: 16px !important;
  text-align: center;
  color: #000000 !important;
  text-transform: capitalize !important;
}

.category .proceed-content .proceed-btn {
  border-radius: 20px !important;
  background: linear-gradient(270deg, #6C328B 0%, #F42074 44.66%, #EF2B4B 100%) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  height: 50px !important;
  text-align: center;
  color: #FCFCFD !important;
  text-transform: capitalize !important;
}
@media  only screen and (max-width:1200px) {
  .category .tiles-container {
    justify-content: start !important;
  }
}
@media only screen and (max-width:1024px){
  .category .tiles-container {
    justify-content: center !important;
  }
}
@media  only screen and (max-width:900px) {
  .category .tiles-container {
    justify-content: start !important;
  }
}