.player-wrapper {
  position: relative;
  padding-top: 56.25%
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.dialog_libraryPersonle {
    max-width: 204px;
    box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
    border-radius: 10px;
    margin-top: 9%;
    margin-left: 65%;
    max-height: 65%;
}

.rangeInput {
  width: 100%;
}

.rangeInput .MuiSlider-track {
  height: 10px;
  border-radius: 15px;
  background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
}

.rangeInput .MuiSlider-rail {
  height: 10px;
  border-radius: 15px;
  background: #9E9E9E;
}

.rangeInput .MuiSlider-thumb::after{
  position: unset;
}

.rangeInput .MuiSlider-thumb {
  background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
  margin-top: -3px;
  width: 16px;
  height: 16px;
}

.react-pdf__Page__canvas {
  max-height: 438px !important;
  width: 100% !important;
}

.pdfDialogBox .react-pdf__Page__canvas {
  height: 100% !important;
  max-height: 95.2% !important;
  width: 100% !important;
}

.pdfDialogBox .MuiDialog-paper {
  overflow-y: hidden !important;
}
.pdfDialogBox .documentConteiner {
  height: 100vh !important;
  width: 100% !important;
}
.numberStyles {
  padding: 10px 15px;
  border-radius: 12px;
  margin-right: 15px;
  border: 1px solid rgba(225, 225, 225, 0.577997);
  color: #6C328B;
  font-weight: 700;
  box-shadow: 0px 0px 16px rgba(213, 213, 213, 0.350581);
}

.cursor {
  cursor: pointer;
}
.videoPlayActiv {
  cursor: pointer;
  border: 1px solid #6c328b;
  box-shadow: 0px 0px 11px #6c328b, 0px 0px 0px #6c328b;
}

.videoPlayList:hover {
  cursor: pointer;
  border: 1px solid #6c328b;
  box-shadow: 0px 0px 11px #6c328b, 0px 0px 0px #6c328b;
}

.cardStyle {
  border-radius: 13px;
  background: #e0e0e0;
  box-shadow: 7px 7px 13px #bebebe, -7px -7px 13px #ffffff;
}

.cardStyle1 {
  border-radius: 16px;
  background: #e0e0e0;
  box-shadow: 11px 11px 22px #bababa,
    -11px -11px 22px #ffffff;
}


.imageView {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.image2View {
  border: 1px solid green;
  margin-left: 10px;
  position: absolute;
  margin-top: -75px;
}

.next {
  background-color: #1c1a1a;
  width: 10px;
  color: white;
}

.round {
  border-radius: 50%;
}

.my-carousel {
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  max-width: 300px;
  z-index: -1;
}

.image {
  display: block !important;
  width: 100% !important;
  height: 243px !important;
  border-radius: 25px 25px 0 0 !important;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 76px;
  text-align: center;
  border-radius: 25px 25px 0 0 !important;
}

.container .overlay {
  opacity: 1;
}

.container1 {
  position: relative;
  width: 100%;
  z-index: -1;
}

.image1 {
  display: block !important;
  width: 100% !important;
  height: 243px !important;
  border-radius: 25px 25px 0 0 !important;
}

.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  min-Width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  border-radius: 25px 25px 25px 25px !important;
  height: 100%;
}

.container1 .overlay1 {
  opacity: 1;
}

.BarChart {
  height: 100%;
  width: 100%;
}

.inputRounded .MuiOutlinedInput-input {
  padding: 18.5px 32px;
}

.Rounded .MuiOutlinedInput-input {
  padding: 18.5px 45px;
}

.Rounded .MuiOutlinedInput-root {
  border-radius: 10px;
  height: 45px;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 10px;
}

.dialog .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 65%;
  margin-top: 15%;
  max-height: 65%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}


.dialog_library .MuiDialog-paperWidthSm {
  max-width: 204px;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
  margin-top: 16%;
  margin-left: -10%;
  max-height: 65%;
}

.dialogSort .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 80%;
  margin-top: 14%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}

.dialogSort_library .MuiDialog-paperWidthSm {
  max-width: 204px;
  margin-left: 10%;
  margin-top: 16%;
  box-shadow: rgb(0 0 0 / 45%) 0px 3px 10px;
  border-radius: 10px;
}

.react-quill .quill {
  height: 140px !important;
}

.react-quill .ql-formats {
  float: right;
  display: flex;
  flex-direction: row-reverse;
}

.react-quill .ql-container {
  height: 140px !important;
}

.react-quill .ql-toolbar.ql-snow {
  border-radius: 15px 15px 0 0;
}

.react-quill .ql-container.ql-snow {
  border: 1px solid #ccc;
  border-radius: 0 0 15px 15px;
}

.react-quill {
  margin: 10px 0;
  border-radius: 10px;
}

.react-quill .ql-editor {
  height: 140px !important;
}

.MuiAccordion-root.Mui-expanded{
  max-width: 100% !important;
}

@media only screen and (max-width: 600px) {
  .paper {
    width: 85vw;
  }

}

@media only screen and (min-width: 600px) {
  .paper {
    width: 85vw;
  }

}

@media only screen and (min-width: 768px) {
  .paper {
    width: 85vw;
  }

}

@media only screen and (min-width: 992px) {
  .paper {
    width: 85vw;
  }
}

@media only screen and (min-width: 1200px) {
  .paper {
    width: 90vw;
  }
}


.TextInput input {
  height: 145px;
}



.cutomCarouselBox .carousel {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin: auto;
}

.cutomCarouselBox .carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 461px;
}

.cutomCarouselBox .carousel-item {
  position: relative;
  flex: 1 0 auto;
  box-sizing: border-box;
}

.cutomCarouselBox .carousel-item img {
  width: 100%;
  height: 100%;
  display: block;
}

.cutomCarouselBox .carousel-text {
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.6); 
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: end;
  flex-direction: column;
}

.cutomCarouselBox .carousel-text h2 {
  margin: 0;
  font-size: 18px;
}

.cutomCarouselBox .carousel-text p {
  margin: 5px 0 0;
  font-size: 14px;
}

.cutomCarouselBox .carousel-button {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  z-index: 2;
  border-radius: 50%;
}

.cutomCarouselBox .carousel-button.prev {
  right: 50px !important;
  left: auto;
}

.cutomCarouselBox .carousel-button.next {
  right: 10px !important;
}

.cutomCarouselBox .next {
  width: 32px !important;
}

.cutomCarouselBox .carousel-dots {
  text-align: center;
  margin-top: 20px;
}

.cutomCarouselBox .dot {
  height: 6px;
  width: 56px;
  margin: 0 5px;
  background-color: #bbb;
  display: none;
  cursor: pointer;
}

.dot {
  border-radius: 8px;
}

.cutomCarouselBox .dot.active {
  background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
}

.carousel-item img {
  border-radius: 15px;
}

.carousel-text {
  border-radius: 15px;
}

.MuiSlider-root {
  color: #FFFFFF !important;
}

.graphGrid {
  display:flex;
  justify-content:space-evenly;
  align-items: flex-end;
}
.customSelect {
  width: 190px;
 height: 45px;
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(146, 144, 144, 1);
  text-transform: capitalize
}
.customSelect .MuiSvgIcon-root{
  margin-right: 10px;
}
.MuiSelect-select :focus{
  background-color: none !important;
}
.containerGrid {
  /* gap: 20px; */
  display:flex;
  justify-content:start; 
  /* margin: 0 20%; */
}

.lineChart2Css {
width: 100%;
  height: 300px;
}

.gridItem {
  margin: 0 5%;
  width: 260px;
}

.videoPlayerlargeScreen iframe {
  display: block;
}

.videoPlayerlargeScreen div:first-of-type {
  width: 100% !important;
  height: 100% !important;
}

.videoPlayerlargeScreen {
  position: relative;
}

.videoPlayerId iframe {
  display: block;
}

.videoPlayerId12 iframe {
  display: block;
}

.videoPlayerId div:first-of-type {
  width: 100% !important;
  height: 480px !important;
}

.accordionCoustom .MuiAccordion-root.Mui-disabled {
background-color: transparent !important;
}

.videoPlayerId {
  position: relative;
}

@media (min-width: 1024px) {
  .cutomCarouselBox .carousel-inner {
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cutomCarouselBox .carousel-inner {
    flex-wrap: nowrap;
  }
  .cutomCarouselBox .carousel-text button{
      font-size: 12px !important;
      padding: 6px 12px !important;
  }
}

@media (max-width: 767px) {
  .cutomCarouselBox .carousel-inner {
    flex-wrap: nowrap;
  }

  .cutomCarouselBox .carousel-text {
    font-size: 14px;
    padding: 8px;
  }

  .cutomCarouselBox .carousel-text h2 {
    font-size: 16px;
  }

  .cutomCarouselBox .carousel-text p {
    font-size: 12px;
  }
  .cutomCarouselBox .carousel-text button{
      font-size: 12px !important;
      padding: 6px 12px !important;
  }
  .containerGrid{
    gap:0;
    max-width: 343px;
  }
  .coniunueNextLessonBtn {
    font-size: 12px !important;
    padding: 6px 12px !important;
    width: 100% !important;
    height: 100% !important;
  }
  .lineChart2Css {
    height: 100% !important;
    width: 100% !important;
    }
    .graphGrid{
      align-items: flex-start;
    }
}
