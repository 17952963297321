.user-management .label-reward {
    margin: 8px;
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #1a1a1a;
}

.user-management .create-user-btn {
    background: #652786 !important;
    border-radius: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: #FFFFFF !important;
    height: 56px !important;
    width: 194px !important;
}

.user-management .send-invite-user-btn {
    color: #653889 !important;
    border-radius: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    background: #ffffff !important;
    text-transform: capitalize !important;
    height: 55px !important;
    width: 190px !important;
    border: 2px solid #653889 !important;
}

.user-management .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d6d6d6 !important;
    border-radius: 18px !important;
}

.user-management .MuiSelect-select:focus {
    background-color: #ffffff;
}

.user-management .MuiSelect-outlined.MuiSelect-outlined {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.user-management .filter-label > p {
    color: #222836;
}

.user-management .bulk-action {
    width: 100% !important;
}

.user-management .select-filter .MuiSelect-select:focus {
    background-color: #fff;
}

.user-management .select-filter .MuiInputBase-input {
    height: unset !important;
}

.user-management .search .MuiInputBase-input {
    height: unset !important;
}

.user-management .search .MuiSvgIcon-root {
    font-size: 1.9rem !important;
}

.customRadio {
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.25);
    display: inline-flex;
    overflow: hidden;
    margin: 20px 0px;
    background: #ecedf2;
    padding: 5px;
    margin-left: 16px;
    height: 48px;
    max-width: 916px;
}

.radio__input {
    display: none;
}

.radio__label {
    padding: 7px 14px;
    font-size: 18px;
    background: #ecedf2;
    cursor: pointer;
    transition: background 0.1s;
}
.radio__input:checked + .radio__label {
    background: #ffffff;
    font-weight: 600;
    border-radius: 8px;
}

@media screen and (max-width: 960px) {
    .MuiGrid-item ,.MuiFormControl-root {
      width: 100% !important;
    }
  }