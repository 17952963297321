.splash .custom-card {
    text-align: center !important;
    width: 100% !important;
}

.splash .back-btn {
    border-radius: 20px !important;
    height: 48px !important;
    background: #FF8B3F !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-align: center;
    color: #FCFCFD !important;
    text-transform: capitalize !important;
}

.splash .MuiPaper-elevation1 {
    box-shadow: none;
}
.term .MuiList-root ol{
    padding: 0px;
    font-size: 14px;
}
.splash .mainheading {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 25px !important;
    margin-left: 55px;
    line-height: 0px !important;
    letter-spacing: -1px !important;
    color: #11142D !important;
    margin-top: 55px !important;
}
.splash .MuiPaper-rounded{
    border-radius: 16px !important;
}
@media only screen and (max-width:600px) {

    .splash .MuiList-root {
        width: 90% !important;
        margin: auto !important;
        position: relative;
        background-color: #fff !important;
        font-size: 11px;
        list-style-type: decimal !important;
        text-align: start;
        padding: 0px 40px;
    }

    .splash .MuiGrid-grid-sm-6 {
        width: 50% !important;
    }
    .splash .heading {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 25px !important;
        margin-right: 30%;
        line-height: 0px !important;
        letter-spacing: -1px !important;
        color: #11142D !important;
        margin-top: 15% !important;
    }
    .splash .mainheading {
        font-size: 18px !important;
    }
}

@media only screen and (min-width:900px) {
   
    .splash .MuiList-root {
        width: 100% !important;
        margin: auto;
        position: relative;
        background-color: #fff;
        font-size: 11px;
        list-style-type: decimal;
        text-align: start;
        padding: 0px 40px;
    }
    .splash .heading {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 25px !important;
        margin-right: 30%;
        line-height: 0px !important;
        letter-spacing: -1px !important;
        color: #11142D !important;
        margin-top: 15% !important;
    }
}


@media only screen and (max-width:1000px) {
    .splash .MuiList-root {
        width: 100% !important;
        margin: auto;
        position: relative;
        background-color: #fff;
        font-size: 11px;
        list-style-type: decimal;
        text-align: start;
        padding: 0px 40px;
    }
}


.splash .MuiCard-root {
    overflow: auto !important;
}

.login-main {
    min-height: 10rem;
    margin: auto;
    width: 100%;
    padding: 100px .5rem;
}

.login-inner {
    color: white;
    font-size: 1.5rem;
    margin: 0 auto;
    max-width: 22%;
    padding: 0;
    text-align: center;
}

.logo-text {
    padding-top: 65px;
    font-size: 40px !important;
    text-align: center;
    margin: auto !important;
    color: #fff;
    font-weight: 700 !important;
    width: 70%;
}