.all .MuiTableSortLabel-root{
    justify-content: center !important;
}
.first .MuiTableSortLabel-root{
    justify-content: start !important;

}

.borderNone {
    border:none !important;
}
@media screen and (max-width: 900px) {
    #last .MuiTableSortLabel-root{
       padding-right: 40px !important;
    }
}

#last .MuiTableSortLabel-root{
    justify-content: start !important;
    padding-left: 20px !important;
    border:none !important;


}
.MuiTableRow-root{
    box-shadow: unset !important;
    border-spacing: 0 15px !important;
    border:none !important;
    
  }
  .MuiTableBody-root .MuiTableRow-root{
    box-shadow: 1px 2px 8px 1px rgba(0,0,0,.05) !important;
  }
   
  

  .MuiPaper-elevation1{
    box-shadow:none !important;
    width: 100% !important;
  }
  .MuiAccordionDetails-root{
    box-shadow:1px 2px 10px 1px #00000011;
    margin-bottom: 10px;
    border-radius:8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiAccordion-root.Mui-expanded{
    max-width: 304px !important;
  }

  .MuiAccordionSummary-content{
    justify-content: space-between;
  }
