.p-root-12.media_title span{
    word-wrap: anywhere;
}

.accordionCoustom .MuiAccordion-root.Mui-disabled {
    background-color: transparent !important;
    }

.accordionCoustom .MuiAccordionSummary-content {
    justify-content: start;
    gap: 5px;
    align-items: start;
}

.accordionCoustom .MuiAccordionSummary-root {
    padding: 0;
}