.register .login-route{
     width: 100%;
 }

 .register .login-route .MuiPaper-elevation1{
  float: right;
  box-shadow: none;
}
.register .login-route  .text{
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #23262F !important;
  cursor: pointer !important;
  background: none !important;
  text-decoration: none !important;
}

.register .form {margin-top: 10px;}

.register .form .uiTypography-root .MuiTypography-h3{font-weight: bold; text-align: start;}

.register .regform{justify-content: center;}

.register .MuiTypography-h5 { font-size: 32px !important; font-weight: 700 !important;color: #23262F !important;font-style: normal !important;}

.register .MuiTypography-h6 { font-size: 14px !important; font-weight: 400;color: #777E90 !important; font-style: normal !important;}

.register .MuiFormLabel-root {font-size: 12px !important; font-weight: 501 !important;color: #959595 !important;font-style: normal !important;}

.register .MuiOutlinedInput-root {border-radius: 12px !important;}

.register .MuiInputLabel-root {margin-bottom: 8px;margin-top: 5px;}

.register .MuiTypography-body1 {font-size: 12px !important;}

.register .MuiButton-root {border-radius: 24px !important;background-image: linear-gradient(270deg, #6C328B 0%, #F42074 44.66%, #EF2B4B 100%)!important;font-style: normal !important;font-weight: 700 !important;font-size: 16px !important;color: #FCFCFD !important;text-transform: capitalize !important;}

.register .MuiInputBase-input {font-size: 13px !important;height: 0.5em !important;}

.register .MuiInputBase-input:focus {font-size: 13px !important;}

.register .MuiSvgIcon-root {font-size: 0.9rem !important;}

.register .typo { width: 100% !important; margin-top: 46px !important; margin-bottom: 26px !important;align-self: end;}

.pr .MuiOutlinedInput-input { padding: 18.5px 53px 18.5px 14px !important;}

.invalid-feedback {width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;}

.register #filled-adornment-password-helper-text { max-width: 200;}

.register .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color: rgba(230, 232, 236, 1);}

.register .MuiOutlinedInput-notchedOutline {border:2px solid rgba(230, 232, 236, 1) !important;}

.register .MuiInputBase-input:hover {border-color:rgba(230, 232, 236, 1) !important;}

.register .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline { border-color: #f44336 !important;}

.register .MuiFormControlLabel-root .MuiFormControlLabel-label {font-style: normal;font-weight: 400 !important;font-size: 14px !important;line-height: 24px;color: #777E90;}

.register .MuiInputBase-input::placeholder {font-style: normal !important;font-weight: 501 !important;font-size: 14px !important;color: #777e90!important;}
.register .terms {
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500 !important;
  color: #6C328B !important;
  font-style: normal !important;
  background: none !important;
  text-decoration: none !important;
}
.register .MuiFormControl-root{
  width: 100% !important;
}
.register .select_flag{
  width: 100px;
  border:2px solid rgba(230, 232, 236, 1) !important;
  padding: 6.5px 11px !important;
  border-radius: 12px !important;
  margin-right: 14px;
}
.grid_3{
  position: relative;
}
.register .select_flag .MuiIconButton-root{
  padding: 0px;
  right:10px;
  bottom: 10px;
}
.register .MuiSelect-select:focus{
  background-color: transparent;
}
.register .MuiSelect-select.MuiSelect-select{
  padding-right: 0px;
  display: flex;
   align-items: center;
  
}

 .flag_img{
  font-size: 18px;
  padding-right: 10px;
}
.register .flag_Text{
  font-size: 14px;
  font-weight: 500;
  color:#92929D;
  padding-left: 5px;
  margin-right: 17px !important;
}
.menu_box {
   padding-left: 2px !important;
   padding-right: 8px !important;
   gap:10px !important;
   color:#6C328B !important;
   font-size: 14px !important;
   font-weight:500 
}
.triangle {
  position: absolute;
  top: 30px;
   right: 3px;

    width: 0;

  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid #d2d2d240;
  border-top: 0 solid transparent;
}
.triangle::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 33px solid #fff;
  position: absolute;
  top: 5px;
  left: -16px;
}


@media screen and (max-width: 960px) {
  .MuiGrid-item ,.MuiFormControl-root {
    width: 100% !important;
  }
}

@media screen and (max-width: 1020px) {
  .phoneContainer {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  .register .select_flag {
    margin-right: 9px;
  }
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}