.canvas canvas {
  height: 160px !important;
}

.canvas-details {
  width: 80% !important;
}
.canvas-details canvas {
  height: 300px !important;
}
