.first_box,
.second_box{
    padding: 30px;
    background: #f2f2f2;
    border-radius: 15px;
}

.first_box input,
.second_box input{
    border-radius: 15px;
    background: #fff;
    padding: 20px 10px;
    width: 100%;
    border: 1px solid #d0d0d0;
    padding-left: 50px;
font-size: 20px;
}

.search_icon{
    margin-top: 15px;
    position: relative;
}

.search_icon img{
    position: absolute;
top: 50%;
left: 14px;
transform: translate(0%, -50%);
}

.list input{
    width: auto;
    margin-right: 30px;
}

.list_heading, .list_row{
    display: flex;
justify-content: flex-start;
align-items: center;
border-bottom: 1px solid #d9d9d9;
padding-top: 25px;
padding-bottom: 10px;
padding-left: 10px;
}

.list_heading input{
    visibility: hidden;
}

.list_heading p{
    font-size: 17px;
    font-weight: 600;
}

.list_row p{
    font-size: 17px;
}

.second_box .search_icon{
    width: 60%;
}

.second_box  p{
    width: 30%;
    text-align: center;
}

.select_box{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 80px;
    display: flex;
}

.select_box p{
    padding: 20px 50px;
font-size: 18px;
}

.content_select{
    width: 412px;
}

.content_select div{
    border: none !important;
}

.content_select select{
    height: 56px;
    padding-left: 10px;
    font-size: 18px;
    background: #FAFAFA;
border: 1px solid rgba(0, 0, 0, 0.12);
border-radius: 8px;
}

.content_select .MuiNativeSelect-icon{
    right: 10px;
    top: calc(50% - 22px);
    font-size: 45px;
}

.content_select .MuiInput-underline::before{
    display: none
}

/* .react_calendar{
    margin-top: 25px;
}

.react_calendar .react-calendar{
    width: 350px;
    border:none;
}

.react-calendar .react-calendar__navigation{
    margin: 0;
    border-bottom: 1px solid #dbdbdb;
}

.react-calendar .react-calendar__month-view__weekdays abbr{
    text-decoration: none;
    color: #7b1fa2;
}

.react-calendar .react-calendar__month-view__days__day{
    background: #d2d2d2;
font-weight: bold;
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
    background: #fff;
    border: 1px solid #ccc;
}

.react-calendar .react-calendar__month-view__days button{
    margin: 3px;
    max-width: 44px;
}

.react-calendar .react-calendar__tile--now:enabled{
    background: #EF2B4B;
    color: #fff;
}

.react-calendar .react-calendar__tile--active{
    border-radius: 0px !important;
    font-size: 15px !important;
    background: #EF2B4B !important;
}

.react-calendar .react-calendar__month-view__days__day--weekend{
    background: #6C328B;
    color: #fff;
} */


.optional_box{
    margin-top: 15px;
    display: flex;
}

.optional_box p{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.optional_box p span{
    margin-left: 10px;
    color: #868686;
    font-weight: 500;
}

.notify_users {
    margin-top: 15px;
}

.notify_users p{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.notify_users .notify_button{
    display: flex;
}

.notify_input {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}

.notify_input .notify_field{
    display: flex;
    align-items: center;
}

.notify_input .notify_field input{
    background: #FAFAFA;
    border: 1px solid #DEDEDE;
    border-radius: 18px;
    width: 437px;
    height: 56px;
    font-size: 16px;
    padding: 15px 20px;
}

.notify_input .notify_field p{
  font-size: 20px;
  display: flex;
  margin-left: 15px;
}

.notify_input .notify_field p img{
   width: 25px;
   margin-left: 15px;
  }

  .send{
    width: 100%;
    text-align: end;
    margin-top: -55px;
    margin-bottom: 25px;
  }