.video {
    padding: 0px 20px;
}
.video .file-container {
    border-radius: 8px;
    height: 212px;
    border: 1px dashed #979797;
    justify-content: center;
    margin-top: 15px;
}

.video .file-content {
    height: 55px;
    margin-top: 2%;
    flex-basis: 0 !important;
}

.modal-Paper {
    width: 1200px;
    display: flex;
    margin: 10px auto;
    max-height: 600px;
    overflow-x: hidden;
    padding: 45px;
    border-radius: 15px !important;
}

.video .file-content .upload-btn {
    background: rgba(219, 219, 219, 0.57075) !important;
    border-radius: 12px;
    height: 73px;
    width: 73px;
    box-shadow: none !important;
}

.video .file-content .MuiAvatar-root {
    width: 30px !important;
    height: 70px !important;
}

.video .file-content .MuiAvatar-img {
    width: 45px;
    height: 30px;
}

.video .file-container .drag-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: #11142D;

}

.video .file-container .drag-text .drag-browse {
    color: rgba(122, 48, 137, 1);
    font-weight: 700;

}

.video .drag-subtext {
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    color: #11142D;
    opacity: 0.5;
    margin: 0px;
    text-align: center;


}

.video .or {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    color: #000000;
    margin: 10px;
    text-align: center;
}

.video .or-container {
    justify-content: center;
}

.video .select-image {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin: 0;
}

.video .btn-thumbnail {
    font-style: normal !important;
    text-decoration: none !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 16px !important;
    text-align: center !important;
    letter-spacing: 0.857143px !important;
    color: #FFFFFF !important;
    background: #FF8B3F !important;
    text-transform: capitalize !important;
    height: 50px !important;
    border-radius: 18px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
    margin-top: 5% !important;
}

.video .custom-card .MuiPaper-elevation1 {
    box-shadow: none !important;
}

.video .custom-card-preview .MuiPaper-elevation1 {
    box-shadow: none !important;
}

.video .link-heading {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #1A1A1A !important;
    margin: 0 !important;
}

.video .link-subheading {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #1A1A1A !important;
    margin: 0 !important;
}

.video .subheading-content {
    height: 25px !important;
    padding: 2px 30px 40px 30px !important;
}

.video .linkheading-content {
    padding: 30px !important;
    height: 30px !important;
}

.video .inputlink-content {
    margin-left: 30px !important;
    margin-bottom: 30px !important;
}

.video .basic {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #11142D;
    margin-left: 10px;
}

.video .input-link .MuiFormLabel-root {

    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: rgba(63, 82, 109, 0.491668) !important;
}

.video .input-link .MuiInput-underline {
    border: none !important;
    border-radius: 4px;
}

.video .input-link .MuiInput-underline::after {
    border-bottom: none !important;
}

.video .library-content .library-heading {

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    text-align: center;
}

.video .library-btn {
    background: rgba(219, 219, 219, 0.57075) !important;
    border-radius: 12px !important;
    width: 73px !important;
    height: 73px !important;
}

.video .vtitle-field .MuiInputBase-input::placeholder {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    /* line-height: 36px !important; */
    color: rgba(63, 82, 109, 0.49) !important;
}

.video .wrapper {
    position: absolute;
    left: 4px;
    right: 4px;
    top: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-end;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    bottom: 3px;
    border-radius: 9px;
}

.video .wrapper-background {
    background: rgba(0, 0, 0, 0.7);
}

.video .wrapper .check {
    position: absolute;
    z-index: 1111;
    top: 34%;
    left: 40%
}

.video .wrapper .check-hide {
    display: none;
}

.video .file-name {

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
    text-align: center;
    margin: 0;
    text-overflow: clip;
    width: 197px;
    overflow: hidden;
    white-space: nowrap;
}

.video .object-desc .MuiInputBase-input::placeholder {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    /* line-height: 36px !important; */
    color: rgba(63, 82, 109, 0.49) !important;
}

.video .custom-card-preview {
    background: #F9F9F9;
    border-radius: 8px;
    height: 100%;
}

.video .checked-content {
    position: absolute;
    top: 40%;
    right: 45%;
}

.video .card-header {
    height: 30px !important;
    text-align: end !important;
}

@media only screen and (max-width:600px) {
    .video .select-img {
        width: 200px;
        height: 200px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .custom-card {
        background: #F9F9F9;
        border: 1px solid rgba(88, 88, 88, 0.0995684);
        border-radius: 8px;
        height: 100%;
    }

    .video .select-img-preview {
        width: 150px;
        height: 150px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .file-container .drag-text {
        font-size: 17px;
    }

    .video .drag-subtext {
        font-size: 15px;
    }

    .video .select-phases {
        width: 310px !important;
    }

    .video .input-link .MuiInputBase-input {
        width: 100% !important;
    }

    .video .btn-thumbnail {
        width: 290px !important;
    }
}

@media only screen and (min-width:1000px) {
    .video .select-img {
        width: 200px;
        height: 200px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .select-img-preview {
        width: 150px;
        height: 150px;
        border: 0.5px solid rgba(0, 0, 0, 0.101644);
        box-sizing: border-box;
    }

    .video .custom-card {
        background: #F9F9F9;
        border: 1px solid rgba(88, 88, 88, 0.0995684);
        border-radius: 8px;
        height: 100%;
    }

    .video .file-container .drag-text {
        font-size: 24px;
    }

    .video .drag-subtext {
        font-size: 16px;
    }

    .video .select-phases .MuiSelect-select {
        width: 625px !important;
    }

    .video .input-link .MuiInputBase-input {
        width: 370px !important;
    }

    .video .btn-thumbnail {
        width: 328px !important;
    }
}

.invalid-feedback {
    display: block;
    color: #dc3545;
    margin-top: 0.25rem;
    width: 100%;
}