.progress-0 >.MuiLinearProgress-bar1Determinate{
    background-color: purple !important;
}

.progress-1 >.MuiLinearProgress-bar1Determinate{
    background-color: yellow !important;
}

.progress-2 >.MuiLinearProgress-bar1Determinate{
    background-color: rgb(182, 82, 98) !important;
}

.progress-3 >.MuiLinearProgress-bar1Determinate{
    background-color: green !important;
}

.slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
  }
  
  .slick-slide img {
    width: 100%;
    height: 100%;
  }
  
  .slick-prev,
  .slick-next {
    z-index: 1;
    font-size: 24px;
    color: #000;
    display: block;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  .slick-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0;
    margin: 0;
    flex-direction: row;
  }
  
  .slick-dots li {
    margin: 0 10px;
    position: relative;
    width: 56px;
    height: 6px;
  }
  
  .slick-dots li button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    text-indent: -9999px;
  }
  
  .slick-dots li::before {
    content: '';
    display: block;
    width: 56px;
    height: 100%;
    background: #ddd;
  }
  
  .slick-dots li.slick-active::before {
    background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
  }
  
  @media (max-width: 768px) {
    .slick-dots li {
      width: 10px;
      height: 6px;
    }
  }
  
  .card {
    padding: 10px;
    box-sizing: border-box;
    width: 540px;
    height: 242px;
  }
  
  .card img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .MuiRating-root {
    pointer-events:none;
  }

  .carousel {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: auto;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 242px;
  }
  
  .carousel-item {
    position: relative;
    flex: 1 0 auto;
    box-sizing: border-box;
    width:540px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
    display: block;
  }
  
  .carousel-text {
    position: absolute;
    color: white;
    background-color: rgba(0, 0, 0, 0.6); 
    padding: 25px;
    border-radius: 5px;
    text-align: left;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: end;
    flex-direction: column;
  }
  
  .carousel-text h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .carousel-text p {
    margin: 5px 0 0;
    font-size: 14px;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    z-index: 2;
    border-radius: 50%;
  }
  
  .carousel-button.prev {
    left: 0;
  }
  
  .carousel-button.next {
    right: 0;
  }
  
  .next {
    width: 32px !important;
  }
  
  .carousel-dots {
    text-align: center;
    margin-top: 20px;
  }
  
  .dot {
    height: 6px;
    width: 56px;
    margin: 0 5px;
    background-color: #bbb;
    display: inline-block;
    cursor: pointer;
  }
  
  .dot.active {
    background: linear-gradient(223.1deg, #6C328B 0%, #EF2B4B 93.2%);
  }
  
  @media (min-width: 1024px) {
    .carousel-inner {
      flex-wrap: nowrap;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .carousel-inner {
      flex-wrap: nowrap;
    }
    .carousel-text button{
        font-size: 12px !important;
        padding: 6px 12px !important;
    }
  }
  
  @media (max-width: 767px) {
    .carousel-inner {
      flex-wrap: nowrap;
      height: 192px;
    }
  
    .carousel-text {
      font-size: 14px;
      padding: 8px;
    }
  
    .carousel-text h2 {
      font-size: 16px;
    }
  
    .carousel-text p {
      font-size: 12px;
    }
    .carousel-text button{
        font-size: 12px !important;
        padding: 6px 12px !important;
    }
    .carousel-item {
      width:343px;
      margin: 0 5px;
    }
    .carouselBox{
      padding:0px !important;
      background: none !important;
    }
  }
  