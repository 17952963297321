.course .MuiInputBase-root .MuiSelect-icon {
  top: 10px !important;
}

.course .MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.course .MuiInputLabel-root {
  margin-bottom: 8px;
  margin-top: 5px;
}

.course .MuiInputBase-input {
  height: 1em !important;
  font-size: 16px !important;
  font-weight: 500;
  color: black;
}

.course .MuiInputBase-input:focus {
  font-size: 16px !important;
}

.course .MuiInputBase-input:hover {
  border-color: rgba(230, 232, 236, 1) !important;
}

.course .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(230, 232, 236, 1) !important;
}

.course .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(230, 232, 236, 1) !important;
}

.course .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.course .MuiInput-underline {
  border: none !important;
}

.course .MuiInputBase-input::placeholder {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  color: #1a1a1a !important;
}

.course .duplicate-btn {
  background: #6c328b !important;
  border-radius: 18px;
  width: 152px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: capitalize;
}

.changethumb-btn {
  border: 1px solid #E47004 !important;
  border-radius: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #E47004 !important;
  width: 192px !important;
  height: 56px !important;
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize !important;
}
.archive{
  width: 127px !important;
}
.formSelect .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl {
  border-radius: 8px !important;
}

.thumb-content .MuiGrid-grid-md-2 {
  max-width: 220px !important;
}

.contentRole-btn {
  background: #6c328b !important;
  border-radius: 16px !important;
  width: 220px !important;
  height: 55.9px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.contentRole-btn .plus-icon {
  border-radius: 6px;
  width: 24px;
  font-size: 1.5rem;
}

.contentRoleContent-btn {
  border: 1px solid #6c328b !important;
  width: 200px !important;
  border-radius: 12px !important;
  height: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #6c328b !important;
  font-family:'Poppins', sans-serif !important;
  text-transform: capitalize !important;
}
.no-content {
  font-weight: 500 !important;
  font-size: 24px !important;
  color: #000000 !important;
}
.program-title {
  font-weight: 600 !important;
  font-size: 22px !important;
  color: #1C1B1B !important;
  
}

.contentRole .MuiGrid-grid-md-2 {
  max-width: unset !important;
  flex-basis: 0 !important;
}

.contentRole .custom-md-2 {
  max-width: 180px !important;
}

.contentRole-badge .MuiBadge-badge {
  border: 1px solid black !important;
  background: #fff !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 13px !important;
}

.contentRole-badge .MuiBadge-anchorOriginTopRightRectangle {
  transform: scale(1) translate(18%, -36%) !important;
}

.calender-btn {
  margin-right: 10px !important;
  background: #ffff !important;
  mix-blend-mode: normal !important;
  border-radius: 88px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #000 !important;
  border: 1px solid #652786 !important;
  text-transform: capitalize !important;
}

.calender-btnoutlined {
  margin-right: 10px !important;
  background: #E0D4E7 !important;
  border: 1px solid #dbc7de !important;
  border-radius: 88px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #652786 !important;
  text-transform: capitalize !important;
}

.calender-btndisabled {
  margin-right: 10px !important;
  background: #FCF8F8 !important;
  border: 1px solid #E5E2E1 !important;
  border-radius: 88px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: #949393 !important;
  text-transform: capitalize !important;
}

.add-reward .MuiOutlinedInput-notchedOutline {
  mix-blend-mode: normal !important;
  opacity: 0.84 !important;
  border: 1px solid #545650 !important;
  border-radius: 8px !important;
}

.add-reward .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  mix-blend-mode: normal !important;
  opacity: 0.84 !important;
  border: 1px solid #545650 !important;
  border-radius: 8px !important;
}

.add-reward .input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  color: #000000;
}

.add-reward .user-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  color: #12142b !important;
}

.add-reward .outer-box {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 15px;
}

.add-reward .outer-border {
  background: #fbfbfb;
  border: 0.5px solid rgba(0, 0, 0, 0.504835);
  border-radius: 8px;
  width: 100%;
  height: 376px;
  overflow: auto;
}

.add-reward .outer-border .inner-container {
  padding: 30px 30px 0px 30px;
}

.add-reward .inner-border {
  background: #ffffff;
  border: 1px dashed #cfcfcf;
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
  padding: 0px 15px 0px 15px;
  flex-direction: row;
}

.add-reward .add-more-btn {
  background: #ffffff !important;
  border: 1px solid #6c328b !important;
  border-radius: 8px !important;
  padding: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #6c328b !important;
  text-transform: capitalize !important;
  margin-bottom: 30px;
  width: 100%;
}

.add-reward .inner-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c328b;
  mix-blend-mode: normal;
  opacity: 0.97;
}

.add-reward .inner-container2 {
  padding: 30px 30px 30px 30px;
}

.add-reward .inner-border2 {
  border: 2px solid rgba(116, 118, 113, 0.242488);
  border-radius: 16px;
  text-align: center;
  min-height: 162px;
  padding: 35px;
}

.add-reward .dashed-border {
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 2.5px dashed #979797;
  border-radius: 8px;
  align-items: center;
  min-height: 370px;
}

.add-reward .text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #545650;
  mix-blend-mode: normal;
  opacity: 0.81;
}

.add-reward .or-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #545650;
}

.add-reward .inner-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.add-reward .create-reward-btn {
  background: #6c328b !important;
  border-radius: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #ffffff !important;
  height: 56px !important;
  width: 302px !important;
}

.add-reward .dashed-border-achievement {
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 2.5px dashed #979797;
  border-radius: 8px;
  align-items: center;
  padding: 35px;
}

#files {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.add-reward .img-preview {
  border-radius: 12px;
  width: 100%;
  height: 223px;
}

.add_preview_img {
  padding: 0 20px;
}

.add_preview_img .file-name-container {
  display: flex;
  align-items: center;
}

.add_preview_img .filename-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start;
  padding: 15px !important;
}

.add_preview_img .filename-btn img {
  margin-right: 15px;
}

.add_preview_img .trash-btn {
  padding: 0;
  margin-left: 15px;
}

.add_preview_img .trash-btn img {
  width: 100%;
}

.scroll-box {
  overflow-y: scroll;
}

.scroll-box::-webkit-scrollbar {
  width: 50px; /* Width of the scrollbar */
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #652786; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
}

.scroll-box::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.build-course .build-course-img {
  height: 80px;
  width: 80px;
  border: 1px solid rgba(225, 225, 225, 0.577997);
  filter: drop-shadow(0px 0px 16px rgba(213, 213, 213, 0.350581));
  border-radius: 8px;
}

.build-course .build-content-border {
  background: #ECECEC;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  margin-top: 10px;
}

.custom-textarea::placeholder {
  font-size: 16px;
 
}

.build-course .build-course-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #1a1a1a;
}

.build-course .build-course-subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: #1a1a1a;
}

.build-course .build-container {
  text-align: center;
}

.build-course .MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}

.build-course .build-course-title {
  padding: 3px 10px 13px 10px;
  text-align: start;
}

.build-course .text-center {
  text-align: center;
}

.build-course .build-course-subheading-desc {
  height: 20px;
  overflow: clip;
}

.build-course .customize-border {
  border: 1px solid rgba(225, 225, 225, 0.577997) !important;
  border-radius: 12px !important;
  margin-top: 20px !important;
}

.build-course .customize-dashed-border {
  background: #ffffff !important;
  border: 1px dashed #cfcfcf !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
  overflow: auto;
  max-height: -webkit-fill-available;
  box-shadow: none;
}

.build-course .reward-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #3f526d;
  overflow: clip;

}

.build-course .achieve-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #3f526d;
  overflow: clip;
}

.build-course .reward-icon {
  width: 80px;
  height: 80px;
  background: rgb(244, 32, 116);
  box-shadow: rgba(244, 32, 116, 0.65) 0px 11px 11px -6px;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
}

.build-course .achieve-icon {
  background: #8636a3;
  box-shadow: 0px 11px 11px -6px #6C328BC7;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
}

.build-course .Addmore-icon {
  background: #8636a3;
  box-shadow: 0px 11px 11px -6px #6C328BC7;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  padding: 22px;
}


.build-course .reward-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3f526d;
}

.build-course .aft-text {
  margin: 50px 0 10px 5px;
  font-size: 22px;
  font-weight: 530;
}

.build-course .achieve-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3f526d;
}

.add-reward .filename-btn {
  background: #f7f3f8;
  mix-blend-mode: normal;
  border-radius: 8px;
  height: 64px;
  width: 280px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-reward .change-thumbnail-btn1 {
  background: #ffffff;
  border: 1px solid #6c328b;
  border-radius: 8px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  color: #6c328b;
  width: 100%;
  margin-top: 35px;
}

.course-section.head-text-launch-date {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1a1a1a;
}

.course .sub-text-launch-date {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #9f9f9f;
}

.CourseCreation-listItemTimeline-61 {
  width: 100%;
}

.course-section .MuiFormControl-root.select-notify {
  width: 100%;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.84;
  border: 1px solid #545650;
  border-radius: 8px;
}

.course-section .search .MuiOutlinedInput-notchedOutline {
  border-radius: 16px !important;
  border: 2px solid #dedede !important;
  max-width: 240px;
  width: 100%;
  max-height: 55px;
}

.course-section .MuiFormControl-root.select-phases {
  width: 100%;
  background: #ffffff;

  border-radius: 8px;
}

.course-section .MuiFormControl-root.object-type {
  width: 100%;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.84;
  /* border: 1px solid #545650; */
  border-radius: 8px;
}

.course-section .dashed-border {
  background: #ffffff;
  border: 0.5px dashed #979797;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px 20px 20px 20px;
}

.course-section .MuiInput-underline::after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.course-section .MuiInput-underline {
  border: none;
  border-radius: 4px;
}

.course-section .notify-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1C1B1B;
}


.course-section .calender {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 10px;
}

.category-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #1a1a1a;
}

.form-field .MuiInputBase-input {
  padding: 6px 10px 7px;
  height: 38px;
  border-radius: 8px;
}
.form-field .MuiFilledInput-root{
  background-color: #fff;
}
.form-field .MuiFilledInput-root:hover{
  background-color: #fff;
}
.form-field .MuiFilledInput-root.Mui-focused {
  background-color: #fff;
}
.form-field .MuiFilledInput-root.Mui-disabled {
  background-color: #fff;
}
.select-field .MuiInputBase-input {
  padding: 10px;
  border-radius: 8px;
}
.enabled_box {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 10px 16px 10px;
  border: 2px dashed #6C328B;
  gap: 10px;
  align-items: center;
  justify-content: center
}
.disabled_box {
  display:flex;
  flex-direction:column;
  border-radius:8px;
  padding:16px 10px 16px 10px;
  border:2px dashed #929090;
  gap:10px;
  align-items:center;
  justify-content:center
}
.enabled_text.MuiTypography-body1 {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-align: center;
  color:#545650
}
.disabled_text.MuiTypography-body1 {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-align: center;
    color:#929090
}

.btn-background {
  background: #6c328b !important;
  color: #fff !important;
}

.course-desc {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #000000 !important;
  margin-bottom: "10px";
}

.course-logic-options {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1a1a1a !important;
}

.toggle-show-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  color: #000000 !important;
}

.toggle-hide-label {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  color: #979797 !important;
}

.draft-btn {
  background: #fafafa;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.4px !important;
  color: #ff8b3f !important;
  max-width: 532px !important;
  height: 56px !important;
  text-transform: capitalize !important;
  margin-top: 15px !important;
}

.proceed-content-btn {
  background: #ff8b3f !important;
  max-width: 532px !important;
  height: 56px !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-align: center !important;
  letter-spacing: 0.3px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin-top: 15px !important;
}

.main-content-dialog-grid {
  text-align: center;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.plain-copy {
  width: 48px;
  height: 48px;
}

.dialog-text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fcfcfd;
  margin: 15px 10px 0 0 !important;
}

.quiz-icon {
  width: 48px;
  height: 48px;
  margin: 0;
}

.custom-line::after {
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: #b86ae2;
  width: 177px;
  left: 28%;
  top: calc(50% - 5px);
  rotate: 90deg;
}

.select-img-preview {
  width: 150px !important;
  height: 150px !important;
  border: 0.5px solid rgba(0, 0, 0, 0.101644) !important;
  box-sizing: border-box !important;
}

.custom-card-preview .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.card-header {
  height: 30px !important;
  text-align: end !important;
}

.file-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  text-align: center;
  margin: 0;
  text-overflow: clip;
  width: 197px;
  overflow: hidden;
  white-space: nowrap;
}

.icon-library .MuiCard-root {
  background: #ffffff !important;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.25),
    6px 6px 25px rgba(230, 230, 230, 0.25), 6px 6px 25px rgba(0, 0, 0, 0.12) !important;
  border-radius: 20px !important;
}

.icon-library .MuiCardHeader-content {
  padding: 10px !important;
}

.icon-library .MuiCardHeader-content .MuiTypography-h5 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1a1a1a !important;
}

.icon-library .paper-content {
  text-align: center !important;
  height: 80px;
}

.icon-library .paper-content .icon-svg {
  height: 50px;
  width: 50px;
  margin: 20px;
}

.icon-library .paper-container .paper-text {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  background: #6c328b !important;
  border-radius: 0px 0px 8px 8px !important;
  padding: 10px !important;
  color: #fff !important;
  margin: 0;
  text-align: center !important;
}

.icon-library .paper-container .paper-text .copies-icon {
  width: 15px;
  height: 15px;
  position: absolute;
}

.icon-library .paper-container {
  background: #ffffff !important;
  border-radius: 8px !important;
}

.icon-library .btn-add-icon {
  background: #653889 !important;
  border-radius: 18px !important;
  width: 200px !important;
  height: 56px !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
}

.icon-library .toggle-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 35px !important;
  color: #6c328b !important;
}

.icon-library .MuiSwitch-root {
  margin: 15px !important;
}

.icon-library .folder {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #979797 !important;
  text-decoration: none !important;
}

.icon-library .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.icon-library .toggle-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.icon-library .branch-select {
  width: 100% !important;
}

.icon-library .select-filter {
  width: 100% !important;
}

.icon-library .select-filter .MuiSelect-selectMenu {
  border-right: 1px solid #e7e7e7 !important;
}

.icon-library .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.icon-library .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.icon-library th {
  width: 150px;
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  background: #ffffff;
}

.icon-library td {
  width: 150px;
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  background: #ffffff;
  height: 70px;
}

.icon-library .tableColumn-container {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
}

.icon-library .tableColumn-container:last-child td:last-child {
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
}

.icon-library .tableColumn-container:last-child td:first-child {
  border-bottom-left-radius: 20px;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
}

.add-folder .select-folder-btn {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.25),
    6px 6px 25px rgba(229, 229, 229, 0.25) !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  width: 220px !important;
  height: 70px !important;
  text-transform: capitalize !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #222836 !important;
  justify-content: space-evenly !important;
}

.add-folder .add-folder-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 175px !important;
}

.add-folder .folder-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: start;
  color: #979797;
  text-transform: capitalize;
}

.icon-library .branch-select .label-select {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #979797;
}

.reward-list .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.reward-list .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.reward-list .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.reward-list th {
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
}

.reward-list td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #979797;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
    6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.reward-list td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #979797;
  border-left-color: #97979700;
}

.reward-list td:first-child {
  border: 0.5px solid #979797;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #97979700;
}

.reward-list .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.reward-list .bulk-action {
  width: 100% !important;
}

.reward-list .create-reward-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 175px !important;
}

.reward-list .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1a1a1a;
}

.reward-list .content-role-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #000000 !important;
}

.reward-list .filter-heading {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #545650 !important;
}

.reward-list .input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}

.achievement-list .create-reward-btn {
  background: #653889 !important;
  border-radius: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 60px !important;
  width: 230px !important;
}

.achievement-list .label-reward {
  margin: 8px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: #1a1a1a;
}

.achievement-list .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d6d6d6 !important;
  border-radius: 18px !important;
}

.achievement-list .bulk-action {
  width: 100% !important;
}

.achievement-list .mainContainer {
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 30px;
  overflow-x: auto;
}

.achievement-list .tableContainer {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
}

.achievement-list .tableContainer th {
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #101010;
}

.achievement-list th {
  text-align: center;
  box-shadow: 0px 0px 10px #efefef, 6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  color: #101010;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.achievement-list td {
  text-align: center;
  background: #ffffff;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #979797;
  box-shadow: -6px -6px 25px rgba(230, 230, 230, 0.15),
    6px 6px 25px rgba(229, 229, 229, 0.15);
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #101010;
  height: 50px;
}

.achievement-list td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #979797;
  border-left-color: #97979700;
}

.achievement-list td:first-child {
  border: 0.5px solid #979797;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right-color: #97979700;
}

.build-course .user-box {
  box-shadow: 0px 5px 5px -3px #0000001A;
  border-radius: 8px !important;
  background: #ffffff !important;
  margin-bottom: 15px;
}

/* .course-section .react-calendar {
  width: 100% !important;
  border-radius: 15px !important;
  margin-top: 10px !important;
} */

@media (max-width: 600px) {
  /* .course-section .react-calendar {
      width: 88% !important;
      border-radius: 15px !important;
      margin-top: 10px !important;
      background-color: lightcoral; 
  } */
}

@media (max-width: 1000px) {
  /* .course-section .react-calendar {
      width: 78% !important;
      border-radius: 15px !important;
      margin-top: 10px !important;
      background-color: lightgreen;
  } */
}

@media (max-width: 1200px) {
  /* .course-section .react-calendar {
      width: 63% !important;
      border-radius: 15px !important;
      margin-top: 10px !important;
      background-color: lightblue; 
  } */
}
 
.textField .MuiInputBase-root {
  height: 40px;
  padding: 10px;
  background: #fff;
  border : 1px solid #652786 !important;
  border-radius: 8px;
}

.course-section .course .edit-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #6c328b;
  text-decoration: underline;
  margin: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-summary-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiAccordion-root:before {
  height: 0 !important;
}

@media screen and (max-width: 500px) {
  .thumbpreview {
    text-align: end !important;
  }

  /* .course-section .react-calendar {
    width: 63% !important;
    border-radius: 15px !important;
    margin-top: 10px !important;
    background-color: lightblue; 
} */

  .contentRole {
    display: block !important;
    text-align: center !important;
  }

  .MuiFormControl-fullWidth {
    min-width: 89vw;
  }
}

@media screen and (max-width: 900px) {
  .thumbpreview {
    text-align: end !important;
  }

  /* .course-section .react-calendar {
    width: 78% !important;
    border-radius: 15px !important;
    margin-top: 10px !important;
    background-color: lightgreen; 
} */

  .contentRole {
    display: block !important;
    text-align: center !important;
  }

 
  .MuiFormControl-fullWidth {
    min-width: 89vw;
  }
}

@media screen and (max-width: 1200px) {
  .thumbpreview {
    text-align: end !important;
  }

  .contentRole {
    display: block !important;
    text-align: center !important;
  }

  .MuiFormControl-fullWidth {
    min-width: 89vw;
  }
}

.bottom-title {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #545650 !important;
}

a {
  text-decoration: none !important;
}

.thumbpreview {
  max-width: 56px !important;
  flex-basis: 56px !important;
  border:1px dashed #6c328b;
  border-radius: 8px;
  background-color: #FAFAFA;
  width:56px;
}

.custom-dialog2 .MuiDialog-paperScrollPaper {
  max-height: 170px !important;
  height: 100% !important;
  border-radius: 16px !important;
}

#dialog-title .MuiDialogContent-root {
  display: flex !important;
}

.build-course .MuiDialog-container .MuiPaper-rounded {
  border-radius: 16px !important;
}
.thumbnailImg{
  box-shadow: 0px 2px 14px 0px rgba(140, 140, 140, 0.7153);
  border-radius: 8px;
  height: 56px;
  width:56px;
}
.thumbnailwidth{
  width: 56px;
}
.build-course .build-content-border .user-box .blur-background {
  opacity: 0.4 !important;
}

.build-course .build-content-border .user-box .active-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #6c328b;
}

.build-course .build-content-border .user-box .inactive-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.no-btn {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  padding: 19px 25px !important;
  gap: 10px !important;
  width: 160px !important;
  height: 56px !important;
  border: 1px solid #6c328b !important;
  border-radius: 16px !important;
  color: #6c328b !important;
}

.yes-btn {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 19px 40px !important;
  gap: 10px !important;
  width: 160px !important;
  height: 56px !important;
  background: #6c328b !important;
  border-radius: 16px !important;
  color: white !important;
}

.custom-dialog3 .MuiDialog-paperScrollPaper {
  background: #fafafa !important;
  box-shadow: 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 12px !important;
}

#alert-dialog-title-delete .MuiTypography-h6 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #000000 !important;
}

#alert-dialog-description-delete {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.3px !important;
  color: #000000 !important;
}

.custom-line1::after {
  content: " ";
  position: absolute;
  background: #b86ae2;
  top: -41px;
  right: 0;
  width: 1px;
  height: 167px;
}

.dialog-text1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #fcfcfd;
  margin: 15px 3px 0 0 !important;
}

.existing_course .MuiOutlinedInput-notchedOutline {
  border: 1px solid #D6D6D6 !important;
  border-radius: 18px !important;
}

.existing_course .filter-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #545650;
}

.existing_course .confirm-btn {
  padding: 19px 40px !important;
  gap: 10px !important;
  width: 209px !important;
  height: 56px !important;
  background: #6c328b !important;
  border-radius: 16px !important;
  color: white !important;
  text-transform: capitalize;
}

.labelStyle.MuiTypography-body1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color:#1C1B1B;
}
.customDisabled .MuiInputBase-root.Mui-disabled {
  background:#E5E2E1;
  border: 1px solid #929090 !important
}

.customInput .MuiInputBase-root {
  margin-top: 6px;
  padding: 10px;
  border : 1px solid #E5E2E1 !important;
  border-radius: 8px;
  background: #FCF6F6;

}
.color_inut .MuiInputBase-root{
  color:"#000";
  background:"#fff";
  margin-top: 6px;
  padding: 10px;
  border : 1px solid #E5E2E1 !important;
  border-radius: 8px;
}
.customInput1 .MuiInputBase-root {
  margin-top: 6px;
  padding:16px 20px 16px 24px !important;
  border : 1px solid rgb(229, 226, 225) !important;
  border-radius: 8px;
  background: #fcf6f6;
  width:"100%";
}

.customInput2 .MuiInputBase-root {
  margin-top: 6px;
  padding:16px 20px 16px 24px !important;
  border : 1px solid rgb(229, 226, 225) !important;
  border-radius: 8px;
  background: #fff;

}

.customInput3 .MuiInputBase-root {
  margin-top: 6px;
  border : none !important;

}

textarea:focus-visible{
  outline:"2px solid blue",!important;
}

.typographyMedia {
  width: 100%;
  @media (max-width: 504px) {
    width: 90px; 
    word-wrap: break-word;
    font-size: 12px;
  }
}


.customInputTextName {
  height: 56px;
  width: fit-content; 

  @media (max-width: 670px) {
    width: fit-content; 
  }

  @media (min-width: 671px) {
    width: 495px; 
  }
}


.autoComplete .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
  padding-bottom: 11px;
}

.checkBox .MuiCheckbox-colorSecondary.Mui-checked {
  color: #652786; 
}
.datePicker {
  background-color: red !important; /* Example to test if styles apply */
}
.datePicker .MuiPickersModal-dialogRoot .MuiDialog-paperWidthSm {
  width: 310px !important;
  top:70;
  left:70px;
  @media(max-width: 567px){
   top:70px;
  left:0;
    }
}

.datePicker .MuiPickersToolbar-toolbar {
  display: none !important;
}

.textInputStyle {
  margin: 0 !important
}
.textInputStyle .MuiOutlinedInput-input {
    text-transform:uppercase;
  }
  .textInputStyle .css-yk16xz-control {
    color: #000;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    height:56px
  }
  .textInputStyle .css-1pahdxg-control {
    height:56px;
    color: #000;
/* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
  }
  .textInputStyle :hover .css-1pahdxg-control {
    height:56px;
    border-color:#2d6f8f
  }


.MuiInput-underline:hover:not(.Mui-disabled)::before{
  border-bottom: 0px !important;
}
.MuiInput-underline:hover:not(.Mui-disabled)::after{
  border-bottom: 0px !important;
}
.MuiInput-underline::before {
  border-bottom: 0px !important;
}
.MuiInput-underline::after  {
	border-bottom: 0px !important;
}

.switch .MuiSwitch-colorSecondary.Mui-checked {
    color: #6C328B,
  }
.switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #C4AED0,
  }

.switch .MuiSwitch-switchBase {
  color: #545650
}

.switchText {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.switchBase {
  color: #545650
}
.switchBase .checked {
    color: #6C328B
  }
.switchBase .checked + track {
    background: #c4aed0;
    mix-blend-mode: normal;
    opacity: 0.37
  }

.track {
  background: #DEDEDE;
  mix-blend-mode: normal;
  opacity: 0.37
}
.inputwidth{
  width:100% !important;

  @media(max-width: 1100px){
   width:80% !important
  }
  @media(max-width: 960px){
    .MuiFormControl-root {
      width: 80% !important;
    }
   }
   @media(max-width: 768px){
    .MuiFormControl-root {
      width: 100% !important;
    }
   }
}

.mainBox{
  width:80%;
  display:flex;
   gap:60px;
   @media(max-width: 768px){
   flex-direction: column;
   width: 100%;
   gap:10px
   }
}
.thumbnailOuterBox{
  display:flex;
  flex-direction:column;
   gap:4px;
   width:58%;
   @media(max-width: 1100px){
    width:80% !important
   }
 
}
.thumbnailinner{
display:flex; 
justify-content:space-between;
@media(max-width: 827px){
 flex-direction:column;
 gap:10px
 }
}
.heading_text {
  color:#1C1B1B;
  font-weight: 700;
  font-size: 40px;
  margin: 0;
}
.heading_course_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.popup-checkbox {
  &.Mui-checked .MuiSvgIcon-root {
    border-color: grey !important; 
    fill: #652786 !important;    
  }}
.popup-dialog {
  .MuiDialog-paperWidthSm{
  border-radius: 16px !important;
  max-width: 1500px !important;
  width: 80%;
  padding: 32px;
}
}
@media (min-width: 370px) {
  .popup {
    width: 100%; /* Narrow the popup width on medium screens */
    min-width: 400px;
  }
}
@media (min-width: 768px) {
  .popup {
    width: 100%; /* Narrow the popup width on medium screens */
    min-width: 200px;
  }
}

@media (min-width: 1024px) {
  .popup {
    width: 90%; /* Narrow the popup width on large screens */
    max-width: 1300px;
  }
}
.searchfield-popup{
  .MuiInputBase-input {
    min-width: 405px;
  }
.MuiInputBase-root{
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 16px 0 16px 24px;
  height: 56px;
  width: 520px;
}
}
.empty-state-btn {
  .MuiButton-label {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
}
}
.btn-popup {
  .MuiButton-label {
    font-size: 16px !important;
    font-weight: 600 !important
  }
}
